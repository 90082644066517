
import Vue from "vue";
import Editor from "@tinymce/tinymce-vue";
import { authMapActions } from "@/store/modules/auth";

import { init } from "./config";

import { tinyEditorKey } from "./tinyKey";
import { unescape } from "lodash";
interface ITinyEditorData {
  init: any;
  apiKey: string;
  countDownTimer: null | any;
}

export default Vue.extend({
  name: "tinyeditor",
  components: {
    editor: Editor
  },
  props: {
    initialData: {
      type: String,
      default: "",
      required: false
    },
    editorId: {
      type: String,
      default: "tiny-editor",
      required: false
    },
    uploadFn: {
      type: Function,
      required: true
    },
    editorFor: {
      type: String,
      default: ""
    },
    imagePreviewToken: {
      type: String,
      default: "",
      required: true
    },
    attachmentId: {
      type: String,
      default: "",
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function(): ITinyEditorData {
    return {
      init: init(this.uploadFn, this.attachmentId),
      apiKey: tinyEditorKey,
      countDownTimer: null
    };
  },
  computed: {
    content(): string {
      return unescape(this.initialData);
    }
  },
  destroyed() {
    this.stopCountDown();
  },
  methods: {
    ...authMapActions(["authGetAdminPreviewToken"]),
    didInput(vals: string) {
      this.$emit("change", vals);
    },
    blur() {
      this.stopCountDown();
      this.$emit("blur");
    },
    focus() {
      this.startCountdown(6000);
      this.$emit("focus");
    },
    startCountdown(timeout: number) {
      this.countDownTimer = setInterval(() => {
        this.$emit("backgroundSave");
      }, timeout);
    },
    stopCountDown() {
      clearInterval(this.countDownTimer);
      this.countDownTimer = null;
    },
    setContent(content: string) {
      this.content = content;
    }
  }
});
