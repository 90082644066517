import { getFilesPathAPI } from "@/helpers";

export const plugins = [
  "lists media table",
  "advlist autolink lists link image charmap print preview anchor",
  "searchreplace visualblocks code fullscreen",
  "insertdatetime media table code help wordcount",
  "image"
];
export const toolbar = `formatselect casechange | bold italic underline  | link | 
    alignleft aligncenter alignright | 
    bullist numlist outdent indent | removeformat |  image  code`;

export function computeImageUrl(documentId: any): string {
  const url = encodeURI(
    `${getFilesPathAPI()}/file/preview/${documentId}?email=${true}`
  );
  return url;
}

export function transformImageSrcToPreview(
  content: string,
  imagePreviewToken: string
): string {
  const regex = /<img.*?src="(.*?)"/g;
  const array = [...content.matchAll(regex)];
  if (array.length) {
    for (const [, url] of array) {
      const newUrl = url.replace(/\/file\/view\//g, "/file/preview/");
      content = content.replace(
        url,
        `${newUrl}?accessToken=${imagePreviewToken}`
      );
    }
    return content;
  }
  return content;
}

export function transformImageSrcToView(content: string): string {
  const regex = /<img.*?src="(.*?)"/g;
  const array = [...content.matchAll(regex)];
  if (array.length) {
    for (const [, url] of array) {
      const newUrl = url.replace(/\/file\/preview\//g, "/file/view/");
      const [newUrlNoToken] = newUrl.split("?accessToken=");
      content = content.replace(url, newUrlNoToken);
    }
    return content;
  }
  return content;
}

export const init = (uploadFunction: any, attachmentId: string) => ({
  plugins,
  toolbar,
  height: 500,
  relative_urls: false,
  remove_script_host: false,
  document_base_url: window.document.location.origin,
  // link_list: [
  //   { title: "2020 Tax Documents", value: "documents/?year=2020&type=tax" },
  //   { title: "2019 Tax Documents", value: "documents/?year=2019&type=tax" },
  //   { title: "2018 Tax Documents", value: "documents/?year=2018&type=tax" },
  //   { title: "2017 Tax Documents", value: "documents/?year=2017&type=tax" },
  //   { title: "2016 Tax Documents", value: "documents/?year=2016&type=tax" },
  //   {
  //     title: "2020 Property Documents",
  //     value: "documents/?year=2020&type=property"
  //   },
  //   { title: "User Profiles", value: "profiles" },
  //   { title: "User Properties", value: "properties" },
  //   { title: "User Messages", value: "messages" },
  //   { title: "User Documents", value: "documents" }
  // ],
  link_title: false,
  target_list: false,
  link_context_toolbar: true,
  link_quicklink: true,

  placeholder: "Write something",
  init_instance_callback: function() {
    const notification = document.querySelector(
      ".tox .tox-notification--in .tox-notifications-container"
    ) as HTMLElement;
    if (notification) {
      notification.style.display = "none";
    }
  },
  images_upload_handler: async function(
    blobInfo: any,
    success: any,
    failure: any
  ): Promise<void> {
    try {
      if (attachmentId) {
        await uploadFunction(blobInfo);
        success(computeImageUrl(attachmentId));
      } else {
        await uploadFunction(blobInfo);
        const blobUrl = URL.createObjectURL(blobInfo.blob());
        success(blobUrl);
        URL.revokeObjectURL(blobInfo);
      }
    } catch (error) {
      failure(error);
    }
  },
  menubar: "tokens",
  menu: {
    tokens: { title: "Tokens", items: "Person" }
  },
  setup: function(editor: any): void {
    editor.ui.registry.addNestedMenuItem("Person", {
      text: "Person",
      getSubmenuItems: function() {
        return [
          {
            type: "menuitem",
            text: "firstName",
            onAction: function() {
              editor.insertContent("{firstName}");
            }
          },
          {
            type: "menuitem",
            text: "lastName",
            onAction: function() {
              editor.insertContent("{lastName}");
            }
          },
          {
            type: "menuitem",
            text: "email",
            onAction: function() {
              editor.insertContent("{email}");
            }
          }
        ];
      }
    });
  }
});
